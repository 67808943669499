import { Language } from '../interfaces';

export const getCommunityPagePath = (language: Language, path?: string | null) => {
    if (!path) {
        return `/${language}/content/community`;
    }
    if (language === 'en') {
        return `/${language}/content/${path}`;
    }
    return `/${language}/innhold/${path}`;
};

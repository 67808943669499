import { UseQueryResult } from 'react-query';

export type RemoteData<T> =
    | {
          isLoading: true;
          error: undefined;
          data: undefined;
      }
    | {
          isLoading: false;
          error: Error;
          data: undefined;
      }
    | {
          isLoading: false;
          error: undefined;
          data: T;
      };

export function mapRemoteData<I, O>(input: RemoteData<I>, fn: (val: I) => O): RemoteData<O> {
    if (input.isLoading) {
        return input;
    }
    if (input.error) {
        return input;
    }
    return {
        isLoading: false,
        error: undefined,
        data: fn(input.data),
    };
}

export function remoteDataFromQueryResult<T>(input: UseQueryResult<T, any>): RemoteData<T> {
    if (input.error) {
        return { isLoading: false, error: input.error.toString(), data: undefined };
    }
    if (input.isLoading || !input.data) {
        return { isLoading: true, error: undefined, data: undefined };
    }

    return { isLoading: false, error: undefined, data: input.data };
}

export function remoteDataLoaded<T>(data: T): RemoteData<T> {
    return { isLoading: false, error: undefined, data };
}

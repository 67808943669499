import {
    SanityClothingType,
    SanityContentPage,
    SanityLandingPage,
    SanityPage,
} from '../../graphql-types';
import { Language } from '../interfaces';

type Props = {
    page?: SanityContentPage | SanityLandingPage | SanityPage;
    language?: Language;
};

export const getPathFromInternalPage = ({ page, language }: Props) => {
    if (!page || !page._type) {
        return null;
    }

    const _type = page._type;

    // note:should populate with all types
    switch (_type) {
        case 'page': {
            const { language, slug } = page as SanityPage;
            const lang = language;

            const slugCurrent = slug?.current;

            if (!slugCurrent) {
                return null;
            }
            switch (lang) {
                case 'en':
                    return `/${lang}/content/${slugCurrent}`;
                case 'nb':
                    return `/${lang}/innhold/${slugCurrent}`;
                default:
                    return null;
            }
        }
        case 'landingPage': {
            const { language, path } = page as SanityLandingPage;
            const lang = language;
            if (!path) {
                return null;
            }
            switch (lang) {
                case 'en':
                    return `/${lang}/${path}`;
                case 'nb':
                    return `/${lang}/${path}`;
                default:
                    return null;
            }
        }
        case 'contentPage': {
            const { slug } = page as SanityContentPage;

            const lang = language === 'en' ? 'en' : 'nb';

            const slugByLanguage = slug?.[lang]?.current;

            if (!slugByLanguage) {
                return null;
            }

            switch (lang) {
                case 'en':
                    return `/${lang}/content/${slugByLanguage}`;
                case 'nb':
                    return `/${lang}/content/${slugByLanguage}`;
                default:
                    return null;
            }
        }
        case 'clothingType': {
            const { slug } = page as SanityClothingType;
            const lang = language === 'en' ? 'en' : 'nb';

            const slugByLanguage = slug?.[lang]?.current;

            if (!slugByLanguage) {
                return null;
            }

            switch (lang) {
                case 'en':
                    return `/${lang}/shop/${slugByLanguage}`;
                case 'nb':
                    return `/${lang}/shop/${slugByLanguage}`;
                default:
                    return null;
            }
        }
        case 'shopPage': {
            const lang = language === 'en' ? 'en' : 'nb';
            return `/${lang}/shop`;
        }

        default:
            return null;
    }
};
